var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"width":"100%"}},[_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('div',{attrs:{"slot":"afterFilterWrapper"},slot:"afterFilterWrapper"},[_c('div',[_c('v-card-title',{staticClass:"float-left pa-0"},[_c('v-app-bar',{staticClass:"white pa-0",attrs:{"flat":"","elevate-on-scroll":"","dense":""}},[_c('v-toolbar-title',{staticClass:"font-weight-bold",attrs:{"data-cy":"audit_log_title"}},[_vm._v(" "+_vm._s(_vm.$t('audit.log.title'))+" ")]),_c('v-spacer')],1)],1)],1)])]),_c('div',[_c('loading',{attrs:{"active":_vm.requestLoading,"is-full-page":false},on:{"update:active":function($event){_vm.requestLoading=$event}}}),_c('v-row',{attrs:{"align":"center","id":"filterWrapper"}},[_c('v-col',{staticClass:"filter pl-0",attrs:{"cols":"3"}},[_c('v-icon',{attrs:{"color":"#000066","left":""}},[_vm._v(" mdi-filter ")]),_c('v-menu',{attrs:{"offset-y":"","close-on-click":true,"close-on-content-click":false,"max-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"text-md-caption text-lg-body-1",attrs:{"prepend-icon":"mdi-calendar-range","dense":"","clearable":"","value":_vm.dateRangeText,"placeholder":_vm.$t('audit.log.table.filters.date.label'),"data-cy":"audit_log_date_filter","hide-details":""},on:{"click:clear":function($event){_vm.dates = []}}},'v-text-field',attrs,false),on))]}}])},[_c('v-card',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-date-picker',{attrs:{"range":"","locale":_vm.$i18n.locale},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1)],1)],1)],1),_c('v-col',{staticClass:"filter",attrs:{"cols":"2","data-cy":"audit_type_filter"}},[_c('v-select',{attrs:{"items":_vm.types,"dense":"","multiple":"","menu-props":{ bottom: true, offsetY: true },"hide-details":""},on:{"change":_vm.handleType},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index===0)?_c('span',{staticClass:"text-md-caption text-lg-body-1"},[_vm._v(_vm._s(item.text)+" ")]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectedTypes.length - 1)+") ")]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"data-cy":("audit_type_" + (item.value))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticStyle:{"margin-right":"10px"}},[_c('v-checkbox',{attrs:{"readonly":"","input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('span',[_vm._v(_vm._s(item.text))])])],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}]),model:{value:(_vm.selectedTypes),callback:function ($$v) {_vm.selectedTypes=$$v},expression:"selectedTypes"}})],1),_c('v-col',{staticClass:"filter",attrs:{"cols":"2","data-cy":"audit_severity_filter"}},[_c('v-select',{attrs:{"dense":"","items":_vm.severities,"multiple":"","menu-props":{ bottom: true, offsetY: true },"hide-details":""},on:{"change":_vm.handleSeverity},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index===0)?_c('span',{staticClass:"text-md-caption text-lg-body-1"},[_vm._v(_vm._s(item.text))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectedSeverity.length - 1)+") ")]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"data-cy":("audit_severity_" + (item.value))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticStyle:{"margin-right":"10px"}},[_c('v-checkbox',{attrs:{"readonly":"","input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('span',[_vm._v(_vm._s(item.text))]),_c('v-spacer'),(item.value==='high')?_c('v-icon',{attrs:{"color":"#FF0000","small":""}},[_vm._v(" mdi-circle ")]):(item.value==='medium')?_c('v-icon',{attrs:{"color":"#FF7708","small":""}},[_vm._v(" mdi-circle ")]):(item.value==='normal')?_c('v-icon',{attrs:{"color":"#D9D3E0","small":""}},[_vm._v(" mdi-circle ")]):_vm._e()],1)],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}]),model:{value:(_vm.selectedSeverity),callback:function ($$v) {_vm.selectedSeverity=$$v},expression:"selectedSeverity"}})],1),_c('v-col',{staticClass:"filter",attrs:{"cols":"2","data-cy":"audit_user_filter"}},[_c('v-select',{attrs:{"dense":"","items":_vm.users,"multiple":"","menu-props":{ bottom: true, offsetY: true },"hide-details":""},on:{"change":_vm.handleUser},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index===0)?_c('span',{staticClass:"text-md-caption text-lg-body-1"},[_vm._v(_vm._s(item.text))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectedUsers.length - 1)+") ")]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"data-cy":("" + (item.value.replace(/\s/g, '')))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticStyle:{"margin-right":"10px"}},[_c('v-checkbox',{attrs:{"readonly":"","input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('span',[_vm._v(_vm._s(item.text))])])],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}]),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}})],1),_c('v-col',{staticClass:"filter ",attrs:{"cols":"3"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"mr-5",attrs:{"color":"#000066","outlined":"","rounded":"","data-cy":"filter_reset_btn"},on:{"click":_vm.resetFilter}},[_c('span',{staticClass:"font-weight-bold text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t('audit.log.buttons.reset')))])]),_c('v-btn',{attrs:{"color":"#000066","rounded":"","data-cy":"filter_apply_btn"},on:{"click":function($event){return _vm.applyFilter(false)}}},[_c('span',{staticClass:"font-weight-bold white--text text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t('audit.log.buttons.apply')))])])],1)],1)],1)],1),[_c('v-card',{staticClass:"mx-auto mb-2",attrs:{"width":"95%"}},[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.audit,"item-key":"id","options":_vm.table.options,"hide-default-footer":""},on:{"update:options":function($event){return _vm.$set(_vm.table, "options", $event)}},scopedSlots:_vm._u([{key:"item.updated_date",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('DateFormat',{attrs:{"date":item.updated_date,"showtime":true,"breakline":false,"data-cy":("date_" + index)}})]}},{key:"item.severity",fn:function(ref){
var item = ref.item;
return [(_vm.showIcon)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.severity==='high')?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#FF0000"},on:{"click":_vm.swapIcon}},'v-icon',attrs,false),on),[_vm._v(" mdi-circle ")]):(item.severity==='medium')?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#FF7708"},on:{"click":_vm.swapIcon}},'v-icon',attrs,false),on),[_vm._v(" mdi-circle ")]):_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#D9D3E0"},on:{"click":_vm.swapIcon}},'v-icon',attrs,false),on),[_vm._v(" mdi-circle ")])]}}],null,true)},[(item.severity==='high')?_c('span',[_vm._v(_vm._s(_vm.$t('audit.log.table.filters.severity.high')))]):(item.severity==='medium')?_c('span',[_vm._v(_vm._s(_vm.$t('audit.log.table.filters.severity.medium')))]):_c('span',[_vm._v(_vm._s(_vm.$t('audit.log.table.filters.severity.normal')))])]):[(item.severity==='high')?_c('span',{staticClass:"font-weight-bold",staticStyle:{"color":"#FF0000","cursor":"pointer"},on:{"click":_vm.swapIcon}},[_vm._v(_vm._s(_vm.$t('audit.log.table.filters.severity.high')))]):(item.severity==='medium')?_c('span',{staticClass:"font-weight-bold",staticStyle:{"color":"#FF7708","cursor":"pointer"},on:{"click":_vm.swapIcon}},[_vm._v(_vm._s(_vm.$t('audit.log.table.filters.severity.medium')))]):_c('span',{staticClass:"font-weight-bold",staticStyle:{"color":"#BEB9C4","cursor":"pointer"},on:{"click":_vm.swapIcon}},[_vm._v(_vm._s(_vm.$t('audit.log.table.filters.severity.normal')))])]]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticClass:"font-weight-bold action_statement"},[_c('TextareaSafeDisplay',{attrs:{"value":_vm.statement(item.type, item.data)}})],1)]),_c('small',{staticClass:"caption text-uppercase grey--text"},[_vm._v(" "+_vm._s(_vm.subtitle(item.type))+" ")])]}}],null,true)})],1),_c('div',{staticClass:"text-center pt-2 pb-4"},[(_vm.table.pagination.pageCount>0)?_c('v-pagination',{attrs:{"length":_vm.table.pagination.pageCount,"total-visible":"10","color":"primary"},on:{"input":function($event){return _vm.applyFilter(true)}},model:{value:(_vm.table.pagination.page),callback:function ($$v) {_vm.$set(_vm.table.pagination, "page", $$v)},expression:"table.pagination.page"}}):_vm._e()],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }