<template>
  <v-card
    class="mx-auto"
    width="100%"
  >
    <v-container
      fill-height
      fluid
    >
      <div slot="afterFilterWrapper">
        <div>
          <v-card-title class="float-left pa-0">
            <v-app-bar
              flat
              class="white pa-0"
              elevate-on-scroll
              dense
            >
              <v-toolbar-title
                class="font-weight-bold"
                data-cy="audit_log_title"
              >
                {{ $t('audit.log.title') }}
              </v-toolbar-title>
              <v-spacer />
            </v-app-bar>
          </v-card-title>
          <!-- <v-btn
            right
            absolute
            class="white--text"
            color="#1976D2"
            data-cy="audit_log_download_btn"
          >
            <v-icon left>
              mdi-download
            </v-icon>
            {{ $t('audit.log.buttons.download') }}
          </v-btn> -->
        </div>
      </div>
    </v-container>

    <div>
      <loading
        :active.sync="requestLoading"
        :is-full-page="false"
      />
      <!-- Filter selection -->
      <v-row
        align="center"
        id="filterWrapper"
      >
        <v-col
          cols="3"
          class="filter pl-0"
        >
          <v-icon
            color="#000066"
            left
          >
            mdi-filter
          </v-icon>
          <!-- DATE RANGE FILTER -->
          <v-menu
            offset-y
            :close-on-click="true"
            :close-on-content-click="false"
            max-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                prepend-icon="mdi-calendar-range"
                v-bind="attrs"
                v-on="on"
                dense
                clearable
                :value="dateRangeText"
                :placeholder="$t('audit.log.table.filters.date.label')"
                class="text-md-caption text-lg-body-1"
                @click:clear="dates = []"
                data-cy="audit_log_date_filter"
                hide-details
              />
            </template>
            <v-card>
              <v-row no-gutters>
                <v-date-picker
                  v-model="dates"
                  range
                  :locale="$i18n.locale"
                />
              </v-row>
            </v-card>
          </v-menu>
        </v-col>

        <!-- Types Filter -->
        <v-col
          cols="2"
          class="filter"
          data-cy="audit_type_filter"
        >
          <v-select
            :items="types"
            dense
            multiple
            v-model="selectedTypes"
            @change="handleType"
            :menu-props="{ bottom: true, offsetY: true }"
            hide-details
          >
            <template v-slot:selection="{ item, index }">
              <span
                class="text-md-caption text-lg-body-1"
                v-if="index===0"
              >{{ item.text }} </span>
              <span
                v-if="index === 1"
                class="grey--text text-caption"
              >
                (+{{ selectedTypes.length - 1 }})
              </span>
            </template>
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item
                v-on="on"
                v-bind="attrs"
                #default="{ active }"
                :data-cy="`audit_type_${item.value}`"
              >
                <v-list-item-action style="margin-right:10px">
                  <v-checkbox
                    readonly
                    :input-value="active"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row
                      no-gutters
                      align="center"
                    >
                      <span>{{ item.text }}</span>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </v-col>

        <!-- Severity Filter -->
        <v-col
          cols="2"
          class="filter"
          data-cy="audit_severity_filter"
        >
          <v-select
            dense
            :items="severities"
            multiple
            v-model="selectedSeverity"
            @change="handleSeverity"
            :menu-props="{ bottom: true, offsetY: true }"
            hide-details
          >
            <template v-slot:selection="{ item, index }">
              <span
                class="text-md-caption text-lg-body-1"
                v-if="index===0"
              >{{ item.text }}</span>
              <span
                v-if="index === 1"
                class="grey--text text-caption"
              >
                (+{{ selectedSeverity.length - 1 }})
              </span>
            </template>
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item
                v-on="on"
                v-bind="attrs"
                #default="{ active }"
                :data-cy="`audit_severity_${item.value}`"
              >
                <v-list-item-action style="margin-right:10px">
                  <v-checkbox
                    readonly
                    :input-value="active"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row
                      no-gutters
                      align="center"
                    >
                      <span>{{ item.text }}</span>
                      <v-spacer />
                      <v-icon
                        v-if="item.value==='high'"
                        color="#FF0000"
                        small
                      >
                        mdi-circle
                      </v-icon>
                      <v-icon
                        v-else-if="item.value==='medium'"
                        color="#FF7708"
                        small
                      >
                        mdi-circle
                      </v-icon>
                      <v-icon
                        v-else-if="item.value==='normal'"
                        color="#D9D3E0"
                        small
                      >
                        mdi-circle
                      </v-icon>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
        <!-- User Filter -->
        <v-col
          cols="2"
          class="filter"
          data-cy="audit_user_filter"
        >
          <v-select
            dense
            :items="users"
            multiple
            v-model="selectedUsers"
            @change="handleUser"
            :menu-props="{ bottom: true, offsetY: true }"
            hide-details
          >
            <template v-slot:selection="{ item, index }">
              <span
                class="text-md-caption text-lg-body-1"
                v-if="index===0"
              >{{ item.text }}</span>
              <span
                v-if="index === 1"
                class="grey--text text-caption"
              >
                (+{{ selectedUsers.length - 1 }})
              </span>
            </template>
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item
                v-on="on"
                v-bind="attrs"
                #default="{ active }"
                :data-cy="`${item.value.replace(/\s/g, '')}`"
              >
                <v-list-item-action style="margin-right:10px">
                  <v-checkbox
                    readonly
                    :input-value="active"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row
                      no-gutters
                      align="center"
                    >
                      <span>{{ item.text }}</span>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </v-col>

        <v-col
          cols="3"
          class="filter "
        >
          <v-row justify="end">
            <v-btn
              color="#000066"
              outlined
              rounded
              class="mr-5"
              @click="resetFilter"
              data-cy="filter_reset_btn"
            >
              <span class="font-weight-bold text-capitalize"> {{ $t('audit.log.buttons.reset') }}</span>
            </v-btn>
            <v-btn
              color="#000066"
              rounded
              @click="applyFilter(false)"
              data-cy="filter_apply_btn"
            >
              <span class="font-weight-bold white--text text-capitalize"> {{ $t('audit.log.buttons.apply') }}</span>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <template>
      <v-card
        class="mx-auto mb-2"
        width="95%"
      >
        <v-data-table
          :headers="headers"
          :items="audit"
          item-key="id"
          :options.sync="table.options"
          hide-default-footer
          class="elevation-2"
        >
          <template
            v-slot:[`item.updated_date`]="{ item , index}"
          >
            <DateFormat
              :date="item.updated_date"
              :showtime="true"
              :breakline="false"
              :data-cy="`date_${index}`"
            />
          </template>
          <template v-slot:[`item.severity`]="{ item }">
            <v-tooltip
              top
              v-if="showIcon"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="item.severity==='high'"
                  color="#FF0000"
                  v-bind="attrs"
                  v-on="on"
                  @click="swapIcon"
                >
                  mdi-circle
                </v-icon>
                <v-icon
                  v-else-if="item.severity==='medium'"
                  color="#FF7708"
                  v-bind="attrs"
                  v-on="on"
                  @click="swapIcon"
                >
                  mdi-circle
                </v-icon>
                <v-icon
                  v-else
                  color="#D9D3E0"
                  v-bind="attrs"
                  v-on="on"
                  @click="swapIcon"
                >
                  mdi-circle
                </v-icon>
              </template>
              <span v-if="item.severity==='high'">{{ $t('audit.log.table.filters.severity.high') }}</span>
              <span v-else-if="item.severity==='medium'">{{ $t('audit.log.table.filters.severity.medium') }}</span>
              <span v-else>{{ $t('audit.log.table.filters.severity.normal') }}</span>
            </v-tooltip>
            <template v-else>
              <span
                class="font-weight-bold"
                style="color:#FF0000; cursor: pointer"
                v-if="item.severity==='high'"
                @click="swapIcon"
              >{{ $t('audit.log.table.filters.severity.high') }}</span>
              <span
                class="font-weight-bold"
                style="color:#FF7708; cursor: pointer"
                v-else-if="item.severity==='medium'"
                @click="swapIcon"
              >{{ $t('audit.log.table.filters.severity.medium') }}</span>
              <span
                class="font-weight-bold"
                style="color:#BEB9C4; cursor: pointer"
                v-else
                @click="swapIcon"
              >{{ $t('audit.log.table.filters.severity.normal') }}</span>
            </template>
          </template>
          <template v-slot:[`item.type`]="{ item }">
            <div>
              <!-- <span class="font-weight-bold">{{ statement(item.type, item.data) }}</span> -->
              <span class="font-weight-bold action_statement">
                <!-- <div v-if="item.type === 'name_screening.onboarding'">
                  {{ '進行開戶篩查 \n輸入(name: <span style="color:#9e9e9e !important">{name}</span>, entity type: {entity_type}, year of birth: {year_of_birth}, countries: {countries}, gender: {gender}, categories: {categories}) \n結果 (hit: {hit})' }}
                </div> -->
                <TextareaSafeDisplay
                  :value="statement(item.type, item.data)"
                />
              </span>
            </div>
            <small class="caption text-uppercase grey--text">
              {{ subtitle(item.type) }}
            </small>
          </template>
        </v-data-table>
      </v-card>
      <div class="text-center pt-2 pb-4">
        <v-pagination
          v-if="table.pagination.pageCount>0"
          v-model="table.pagination.page"
          :length="table.pagination.pageCount"
          total-visible="10"
          color="primary"
          @input="applyFilter(true)"
        />
      </div>
    </template>
  </v-card>
</template>

<script>
import moment from 'moment'
import DateFormat from '@/components/common/DateFormat'
import TextareaSafeDisplay from '@/components/common/TextareaSafeDisplay'
import { UserActionSeverity, UserActionType } from 'rba-client'

export default {
  name: 'AuditLog',
  components: {
    DateFormat,
    TextareaSafeDisplay
  },
  computed: {
    // filter related
    dateRangeText () {
      return this.dates.join(' - ')
    },

    types () {
      return [
        { value: 'all', text: this.$t('audit.log.table.filters.types.default') },
        // { value: 'system', text: this.$t('audit.log.table.filters.types.system')},
        { value: 'naturalperson', text: this.$t('audit.log.table.filters.types.naturalperson') },
        { value: 'company', text: this.$t('audit.log.table.filters.types.company') },
        { value: 'transaction', text: this.$t('audit.log.table.filters.types.transaction') },
        { value: 'alert', text: this.$t('audit.log.table.filters.types.alert') },
        { value: 'name_screening', text: this.$t('audit.log.table.filters.types.namescreening') },
        { value: 'import', text: this.$t('audit.log.table.filters.types.dataimport') }
      ]
    },

    severities () {
      return [
        { value: 'all', text: this.$t('audit.log.table.filters.severity.default') },
        { value: UserActionSeverity.Normal, text: this.$t('audit.log.table.filters.severity.normal') },
        { value: UserActionSeverity.Medium, text: this.$t('audit.log.table.filters.severity.medium') },
        { value: UserActionSeverity.High, text: this.$t('audit.log.table.filters.severity.high') }
      ]
    },

    users () {
      return [
        { value: 'all', text: this.$t('audit.log.table.filters.user.default') },
        ...(this.username ? this.username : [])
      ]
    },

    // headers
    headers () {
      return [
        {
          text: this.$t('audit.log.table.headers.date'),
          sortable: false,
          value: 'updated_date',
          width: '15%'
        },
        {
          text: this.$t('audit.log.table.headers.severity'),
          sortable: false,
          align: 'center',
          value: 'severity',
          width: '10%'
        },
        {
          text: this.$t('audit.log.table.headers.activity'),
          sortable: false,
          value: 'type',
          width: '55%'
        },
        {
          text: this.$t('audit.log.table.headers.user'),
          sortable: false,
          value: 'username',
          width: '20%'
        }
      ]
    },

    // table related
    statement () {
      return (type, data) => {
        switch (type) {
          case UserActionType.NaturalPerson_Create:
          case UserActionType.NaturalPerson_Update:
          case UserActionType.NaturalPerson_Delete:
          case UserActionType.NaturalPerson_Export:
          case UserActionType.Company_Create:
          case UserActionType.Company_Update:
          case UserActionType.Company_Delete:
          case UserActionType.Company_Export:
          case UserActionType.Transaction_Create:
          case UserActionType.Transaction_Update:
          case UserActionType.Transaction_Delete:
          case UserActionType.Alert_Resolve:
          case UserActionType.Alert_Modify:
          case UserActionType.NameScreening_Single:
          case UserActionType.NameScreening_Onboarding:
          case UserActionType.NameScreening_Batch_Start:
          case UserActionType.NameScreening_Batch_Stop:
          case UserActionType.NameScreening_Batch_End:
          case UserActionType.NameScreening_Batch_Export:
          case UserActionType.DataImport_Company:
          case UserActionType.DataImport_NaturalPerson:
            return this.$t(`user.action.${type}.statement`, {
              // default
              ...data,
              // special handler
              ref_id: data.ref_id || data.id || ' - ',
              doc_type: data.doc_type || ' - ',
              name: data.name || ' - ',
              nationality: data.nationality || ' - ',
              yob: data.year_of_birth || ' - ',
              // ewe
              year_of_birth: data.year_of_birth || ' - ',
              countries: data.countries && data.countries.length ? JSON.stringify(data.countries) : ' All ',
              gender: data.gender || ' All ',
              categories: data.categories && data.categories.length ? JSON.stringify(data.categories) : ' All '
            })

          // Other
          default:
            return this.$t(`user.action.${type}.title`)
        }

        // if (type === 'other') {
        //   return this.$t(`user.action.${type}.title`)
        // } else {
        //   return this.$t(`user.action.${type}.statement`, {
        //     // default
        //     ...data,
        //     // special handler
        //     ref_id: data.ref_id || data.id || ' - ',
        //     doc_type: data.doc_type || ' - ',
        //     name: data.name || ' - ',
        //     nationality: data.nationality || ' - ',
        //     yob: data.year_of_birth || ' - '
        //   })
        // }
      }
    },

    subtitle () {
      return type => {
        switch (type) {
          // Naturalperson
          case UserActionType.NaturalPerson_Create:
          case UserActionType.NaturalPerson_Update:
          case UserActionType.NaturalPerson_Delete:
          case UserActionType.NaturalPerson_Export:
            return `${this.$t('user.action.naturalperson.title')} > ${this.$t(`user.action.${type}.subtitle`)}`

          // Company
          case UserActionType.Company_Create:
          case UserActionType.Company_Update:
          case UserActionType.Company_Delete:
          case UserActionType.Company_Export:
            return `${this.$t('user.action.company.title')} > ${this.$t(`user.action.${type}.subtitle`)}`

          // Transaction
          case UserActionType.Transaction_Create:
          case UserActionType.Transaction_Update:
          case UserActionType.Transaction_Delete:
          case UserActionType.Transaction_Download:
            return `${this.$t('user.action.transaction.title')} > ${this.$t(`user.action.${type}.subtitle`)}`

          // Alert
          case UserActionType.Alert_Resolve:
          case UserActionType.Alert_Modify:
            return `${this.$t('user.action.alert.title')} > ${this.$t(`user.action.${type}.subtitle`)}`

          // Name Screening
          // single
          case UserActionType.NameScreening_Single:
          case UserActionType.NameScreening_Onboarding:
            return `${this.$t('user.action.name_screening.title')} > ${this.$t('user.action.name_screening.single.subtitle')}`
          // batch status
          case UserActionType.NameScreening_Batch_Start:
          case UserActionType.NameScreening_Batch_Stop:
          case UserActionType.NameScreening_Batch_End:
            return `${this.$t('user.action.name_screening.title')} > ${this.$t('user.action.name_screening.batch.subtitle')} ( ${this.$t(`user.action.${type}.status`)} )`
          // batch //export
          case UserActionType.NameScreening_Batch_Export:
            return `${this.$t('user.action.name_screening.title')} > ${this.$t('user.action.name_screening.batch.subtitle')} ( ${this.$t(`user.action.${type}.subtitle`)} )`

          // Data Import
          case UserActionType.DataImport_Company:
          case UserActionType.DataImport_NaturalPerson:
            return `${this.$t('user.action.import.title')} > ${this.$t(`user.action.${type}.subtitle`)}`

          // Other
          default:
            return `${this.$t('user.action.other.title')}`
        }
      }
    }

  },
  data () {
    return {
      requestLoading: true,
      audit: [],
      criteria: {},
      // filter related
      selectedTypes: ['all'],
      selectedSeverity: ['all'],
      selectedUsers: ['all'],
      username: null,
      dates: [],
      showIcon: true,
      table: {
        options: {
          itemsPerPage: 10,
          sortBy: 'date',
          sortDesc: false
        },
        pagination: {
          page: 1,
          pageCount: 0
        }
      }
    }
  },

  methods: {
    getData () {
      this.criteria.limit = this.table.options.itemsPerPage
      this.criteria.skip = this.criteria.limit * (this.table.pagination.page - 1)
      this.criteria.sort = 'updated_date DESC'
      this.$client.action_log.findWhere(this.criteria)
        .then((results) => {
          this.audit = results.data
          this.requestLoading = false
        })
        .catch(err => {
          console.log(err)
        })
    },

    getUsernames () {
      this.$client.action_log.findUsernames()
        .then(results => {
          this.username = results.data.map((item) => {
            return {
              value: item.username,
              text: item.username
            }
          })
        }).catch(err => {
          console.log(err)
        })
    },

    countPage () {
      this.table.pagination.pageCount = 0
      this.$client.action_log.count(this.criteria)
        .then(result => {
          this.table.pagination.pageCount = Math.ceil(result.data.count / this.table.options.itemsPerPage)
          this.getData()
        }).catch(err => {
          console.log(err)
        })
    },

    handleType (e) {
      if (e[0] === 'all' && e.length > 1) {
        this.selectedTypes.shift()
      }

      if (e.includes('all') || e.length === 0) {
        this.selectedTypes = ['all']
      }

      if (!e.includes('all') && e.length === this.types.length - 1) {
        this.selectedTypes = ['all']
      }
    },

    handleSeverity (e) {
      if (e[0] === 'all' && e.length > 1) {
        this.selectedSeverity.shift()
      }

      if (e.includes('all') || e.length === 0) {
        this.selectedSeverity = ['all']
      }

      if (!e.includes('all') && e.length === this.severities.length - 1) {
        this.selectedSeverity = ['all']
      }
    },

    handleUser (e) {
      if (e.includes('all') && e.length > 1) {
        this.selectedUsers.shift()
      }

      if (e.includes('all') || e.length === 0) {
        this.selectedUsers = ['all']
      }

      if (!e.includes('all') && e.length === this.username.length) {
        this.selectedUsers = ['all']
      }
    },

    resetFilter () {
      this.selectedUsers = ['all']
      this.selectedSeverity = ['all']
      this.selectedTypes = ['all']
      this.dates = []
    },

    applyFilter (pageNav) {
      // filter and get data from server
      this.audit = []
      this.requestLoading = true

      let startDate
      let endDate
      if (this.dates.length === 1) {
        startDate = this.dates[0]
        endDate = moment(moment(startDate).endOf('day')).format('x')
        startDate = (moment(startDate).format('x'))
      } else if (this.dates.length === 2) {
        startDate = moment(this.dates[0]).isBefore(this.dates[1]) ? this.dates[0] : this.dates[1]
        endDate = moment(this.dates[0]).isAfter(this.dates[1]) ? this.dates[0] : this.dates[1]
        startDate = (moment(startDate).format('x'))
        endDate = moment(moment(endDate).endOf('day')).format('x')
      }

      let userActionTypes = []
      if (this.selectedTypes[0] !== 'all') {
        this.selectedTypes.forEach((type) => {
          switch (type) {
            case 'naturalperson':
              userActionTypes = [
                ...userActionTypes,
                UserActionType.NaturalPerson_Update,
                UserActionType.NaturalPerson_Delete,
                UserActionType.NaturalPerson_Create,
                UserActionType.NaturalPerson_Export
              ]
              break
            case 'company':
              userActionTypes = [
                ...userActionTypes,
                UserActionType.Company_Create,
                UserActionType.Company_Update,
                UserActionType.Company_Delete,
                UserActionType.Company_Export
              ]
              break
            case 'transaction':
              userActionTypes = [
                ...userActionTypes,
                UserActionType.Transaction_Create,
                UserActionType.Transaction_Update,
                UserActionType.Transaction_Delete
              ]
              break
            case 'alert':
              userActionTypes = [
                ...userActionTypes,
                UserActionType.Alert_Resolve,
                UserActionType.Alert_Modify
              ]
              break
            case 'name_screening':
              userActionTypes = [
                ...userActionTypes,
                UserActionType.NameScreening_Single,
                UserActionType.NameScreening_Onboarding,
                UserActionType.NameScreening_Batch_Start,
                UserActionType.NameScreening_Batch_Stop,
                UserActionType.NameScreening_Batch_End,
                UserActionType.NameScreening_Batch_Export
              ]
              break
            case 'import':
              userActionTypes = [
                ...userActionTypes,
                UserActionType.DataImport_Company,
                UserActionType.DataImport_NaturalPerson
              ]
              break
            default:
              break
          }
        })
      }

      this.criteria = {
        where: ((() => {
          var _where = { and: [] }
          _where.is_deleted = 0
          if (startDate && endDate) {
            _where.and = [
              { updated_date: { $GreaterEqual: startDate } },
              { updated_date: { $LessEqual: endDate } }
            ]
          }
          if (this.selectedSeverity[0] !== 'all') _where.severity = { $In: this.selectedSeverity }
          if (this.selectedTypes[0] !== 'all') _where.type = { $In: userActionTypes }
          if (this.selectedUsers[0] !== 'all') _where.username = { $In: this.selectedUsers }
          return _where
        })())
      }
      // get all usernames
      if (!this.username) this.getUsernames()
      // check is page navigation or filtering
      if (!pageNav) this.table.pagination.page = 1
      this.countPage()
    },

    swapIcon () {
      this.showIcon = !this.showIcon
    }

  },

  mounted () {
    this.applyFilter()
  }

}
</script>

<style>
    #filterWrapper {
      border-radius: 50px;
      border-width: 2px;
      border-color: #000066;
      border-style: solid;
      width: 95%;
      max-height: 60px;
      /* padding:0px 10px 0px 10px; */
      margin: auto;
      margin-bottom: 20px;
      padding: 8px 10px;
    }

    #filterWrapper * {
         /* max-height: 50px; */
      max-height: 29px;
    }

    #filterWrapper .v-select {
      padding-bottom: 3px;
    }

    .filter {
        display: flex;
        align-items: center;
    }

    .action_statement span i {
      color: #1976d2 !important;
      word-break: break-word;
    }

</style>
